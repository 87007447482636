@import 'mixins';

@font-face {
  font-family: 'BMWTypeNextTT';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/themes/bmw/fonts/BMWTypeNextTT-Regular.eot?') format('eot'),
    url('/assets/themes/bmw/fonts/BMWTypeNextTT-Regular.woff') format('woff'),
    url('/assets/themes/bmw/fonts/BMWTypeNextTT-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'BMWTypeNextTT';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/themes/bmw/fonts/BMWTypeNextTT-Bold.eot?') format('eot'),
    url('/assets/themes/bmw/fonts/BMWTypeNextTT-Bold.woff') format('woff'),
    url('/assets/themes/bmw/fonts/BMWTypeNextTT-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'BMWTypeNextTT';
  font-style: normal;
  font-weight: 300;
  src: url('/assets/themes/bmw/fonts/BMWTypeNextTT-Light.eot?') format('eot'),
    url('/assets/themes/bmw/fonts/BMWTypeNextTT-Light.woff') format('woff'),
    url('/assets/themes/bmw/fonts/BMWTypeNextTT-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'BMWTypeNextTT';
  font-style: normal;
  font-weight: 100;
  src: url('/assets/themes/bmw/fonts/BMWTypeNextTT-Thin.eot?') format('eot'),
    url('/assets/themes/bmw/fonts/BMWTypeNextTT-Thin.woff') format('woff'),
    url('/assets/themes/bmw/fonts/BMWTypeNextTT-Thin.ttf') format('ttf');
}

// Fonts
// $font-family-headlines: 'BMWTypeNextTT';
// $font-family-body: 'BMWTypeNextTT';
// $font-family-buttons: 'BMWTypeNextTT';

// $icon-font: 'md-icon';

// // Colors
// $bmw-button-background-default: #4d4d4d;
// $bmw-button-background-primary: #1c69d4;

// $carousel-dot-color: rgb(230, 230, 230);

.bmw- {
  &body {
    font-family: 'BMWTypeNextTT';
  }

  &info-button {
    position: relative;
    width: 30px;
    height: 30px;
    max-width: 50px;
    max-height: 50px;
    background-color: transparent;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('/assets/themes/bmw/icons/info.svg');
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &video-play-button {
    position: relative;
    width: 10vw;
    height: 10vw;
    max-width: 50px;
    max-height: 50px;
    background-color: #1c69d4;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('/assets/themes/bmw/icons/bmw_media_play_def_d.svg');
      background-size: 65%;
      background-position: center;
      background-repeat: no-repeat;
    }
    &:hover {
      background-color: #0653b6;
      &::after {
        background-image: url('/assets/themes/bmw/icons/bmw_media_play_hov_d.svg');
      }
    }
  }
  &video-pause-button {
    @extend .bmw-video-play-button;

    &::after {
      background-image: url('/assets/themes/bmw/icons/bmw_media_pause_def_b.svg');
    }
    &:hover {
      &::after {
        background-image: url('/assets/themes/bmw/icons/bmw_media_pause_hov_b.svg');
      }
    }
  }
  &video-play-button-driving-experience {
    position: relative;
    width: 10vw;
    height: 10vw;
    max-width: 50px;
    max-height: 50px;
    background-color: transparent;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('/assets/themes/bmw/icons/bmw_media_play_def_drivingexp.svg');
      background-size: 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
    &:hover {
      background-color: #0653b6;
      &::after {
        background-image: url('/assets/themes/bmw/icons/bmw_media_play_hov_d.svg');
      }
    }
  }
  &video-download-button {
    position: relative;
    width: 10vw;
    height: 10vw;
    max-width: 50px;
    max-height: 50px;
    background-color: #1c69d4;
    margin-left: 15px;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('/assets/themes/bmw/icons/bmw_download.svg');
      background-size: 65%;
      background-position: center;
      background-repeat: no-repeat;
    }
    &:hover {
      background-color: #0653b6;
    }

    .download-link {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &env-menu {
    .active {
      color: #0653b6;
    }
  }

  &carousel-arrow-prev {
    width: 10vw;
    height: 10vw;
    max-width: 40px;
    max-height: 40px;
    position: relative;
    background-color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    display: none;
    @include breakpoint('m') {
      display: block;
    }
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('/assets/themes/bmw/icons/bmw_media_previous_def_b.svg');
      background-size: 65%;
      background-position: 35% 50%;
      background-repeat: no-repeat;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.8);
      &::before {
        background-image: url('/assets/themes/bmw/icons/bmw_media_previous_hov_b.svg');
      }
    }
  }

  &carousel-arrow-next {
    @extend .bmw-carousel-arrow-prev;
    &::before {
      background-image: url('/assets/themes/bmw/icons/bmw_media_next_def_b.svg');
      background-position: 50% 50%;
    }
    &:hover {
      &::before {
        background-image: url('/assets/themes/bmw/icons/bmw_media_next_hov_b.svg');
      }
    }
  }

  &carousel-dot {
    background: #e6e6e6;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 0.5rem;
    &.is-current {
      background: #1c69d4;
      width: 10px;
      height: 10px;
    }
  }

  &headline {
    font-weight: 300;
    font-family: 'BMWTypeNextTT';
  }

  &subtitle {
    font-weight: 300;
    font-family: 'BMWTypeNextTT';
  }

  &logo {
    width: 36px;
    @include breakpoint('m') {
      width: 53px;
    }
  }

  &footer {
    background: #e6e6e6;
    color: #1c69d4;
    font-weight: 400;
    font-size: 14px;
    .copyright,
    .disclaimer,
    .emission {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  &separator-container {
    h1 {
      color: #1c69d4;
      font-family: 'BMWTypeNextTT';
    }

    .separator-line {
      background: #1c69d4;
    }
  }

  &video-menu {
    span {
      font-family: 'BMWTypeNextTT';
    }
  }

  &model-header {
    h1 {
      font-weight: 300;
    }
  }

  &scroll-indicator .border {
    background: #1c69d4;
  }

  &carousel__nav {
    .circle-go {
      stroke: #1c69d4;
    }
  }

  &download-in-progress {
    background-color: #1c69d4;
    width: 10vw;
    height: 10vw;
    max-width: 50px;
    max-height: 50px;

    &:hover {
      background-color: #0653b6;
    }
  }

  &timeline {
    progress[value] {
      background: #1c69d4;

      &::-webkit-progress-value {
        background-color: #1c69d4;
      }

      &::-moz-progress-bar {
        background: #1c69d4;
      }
    }
  }

  &volume {
    mat-slider {
      --mdc-theme-primary: #1c69d4;
      --mdc-slider-active-track-color: #1c69d4 !important;
      --mdc-slider-inactive-track-color: rgba(28, 105, 212, 0.7) !important;

      mat-slider-visual-thumb {
        --mdc-slider-handle-color: #1c69d4;
        --mdc-slider-focus-handle-color: #1c69d4;
        --mdc-theme-primary: #1c69d4;
        --mat-mdc-slider-hover-ripple-color: rgba(63, 81, 181, 0.05);
        --mat-mdc-slider-focus-ripple-color: rgba(28, 105, 212, 0.2);
      }
    }

    ::ng-deep {
      .mat-slider.mat-accent .mat-slider-track-fill,
      .mat-slider.mat-accent .mat-slider-thumb,
      .mat-slider.mat-accent .mat-slider-thumb-label {
        background-color: #1c69d4 !important;
      }
    }
  }
}
