/* You can add global styles to this file, and also import other style files */
@import '~normalize.css';
@import 'themes/bmw';
@import 'themes/mini';

.mat-select-arrow,
.mat-select-value {
  color: white;
}

.mat-select,
.mat-option {
  font-family: inherit;
}

.dialog-panel {
  width: 80vw;
  height: 80vh;

  @media only screen and (max-width: 740px) {
    width: 100vw;
    height: 100vh;

    .mat-dialog-container {
      max-width: 100vw !important;
    }
  }
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'sans-serif';
  font-family: 'BMWTypeNextTT';

  &.fix {
    position: relative;
    overflow: hidden;
  }
}

h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

button {
  padding: 0;
  margin: 0;
  border: none;
  background: white;
  outline: none;
}

button {
  cursor: pointer;
}
