@use 'sass:math';

$max-video-width: 2000px;
$max-video-height: $max-video-width / 16 * 9;

$media-direction: min;
$breakpoints: (
  'default': '',
  's': 320px,
  'sm': 500px,
  'm': 768px,
  'l': 992px,
);
@mixin breakpoint($breakpoint, $direction: $media-direction) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (#{$direction}-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  } @else if unitless($breakpoint) {
    $breakpoint: $breakpoint + 0px;
    @media (#{$direction}-width: $breakpoint) {
      @content;
    }
  }
}

@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
    & {
      font-size: $min-font-size;
      @media screen and (min-width: $min-vw) {
        font-size: calc(
          #{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} *
            ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)})
        );
      }
      @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
      }
    }
  }
}
