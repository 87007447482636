@import 'mixins';

@font-face {
  font-family: 'MINISansSerif';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/themes/mini/fonts/MINISansSerif-Regular.eot?') format('eot'),
    url('/assets/themes/mini/fonts/MINISansSerif-Regular.woff2') format('woff2'),
    url('/assets/themes/mini/fonts/MINISansSerif-Regular.woff') format('woff');
}

@font-face {
  font-family: 'MINISansSerif';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/themes/mini/fonts/MINISansSerif-Bold.eot?') format('eot'),
    url('/assets/themes/mini/fonts/MINISansSerif-Bold.woff2') format('woff2'),
    url('/assets/themes/mini/fonts/MINISansSerif-Bold.woff') format('woff');
}

@font-face {
  font-family: 'MINISerif';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/themes/mini/fonts/MINISerif-Regular.eot?') format('eot'),
    url('/assets/themes/mini/fonts/MINISerif-Regular.woff2') format('woff2'),
    url('/assets/themes/mini/fonts/MINISerif-Regular.woff') format('woff');
}

// @font-face {
// 	font-family: "MINISerif";
// 	font-style: italic;
// 	font-weight: normal;
// 	src: url("/assets/themes/mini/fonts/MINISerif-Italic.eot?") format("eot"),
// 	     url("/assets/themes/mini/fonts/MINISerif-Italic.woff2") format("woff2"),
// 	     url("/assets/themes/mini/fonts/MINISerif-Italic.woff") format("woff");
// }

@font-face {
  font-family: 'MINISerif';
  font-style: normal;
  font-weight: bold;
  src: url('/assets/themes/mini/fonts/MINISerif-Bold.eot?') format('eot'),
    url('/assets/themes/mini/fonts/MINISerif-Bold.woff2') format('woff2'),
    url('/assets/themes/mini/fonts/MINISerif-Bold.woff') format('woff');
}

// @font-face {
// 	font-family: "MINISerif";
// 	font-style: normal;
// 	font-weight: bolder;
// 	src: url("/assets/themes/mini/fonts/MINISerif-Headline.eot?") format("eot"),
// 	     url("/assets/themes/mini/fonts/MINISerif-Headline.woff2") format("woff2"),
// 	     url("/assets/themes/mini/fonts/MINISerif-Headline.woff") format("woff");
// }

// @font-face {
// 	font-family: "MINISerif";
// 	font-style: normal;
// 	font-weight: 600;
// 	src: url("/assets/themes/mini/fonts/MINISerif-Medium.eot?") format("eot"),
// 	     url("/assets/themes/mini/fonts/MINISerif-Medium.woff2") format("woff2"),
// 	     url("/assets/themes/mini/fonts/MINISerif-Medium.woff") format("woff");
// }

@font-face {
  font-family: 'md-icon';
  font-style: normal;
  font-weight: normal;
  src: url('/assets/themes/mini/fonts/md-icon.woff') format('woff');
}

// Colors
// $button-background-primary: blue;

// Components

.mini- {
  &body {
    font-family: 'MiniSerif';
  }

  &info-button {
    position: relative;
    width: 3vw;
    height: 3vw;
    max-width: 50px;
    max-height: 50px;
    background-color: transparent;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('/assets/themes/bmw/icons/info.svg');
      background-size: 65%;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &video-play-button {
    position: relative;
    width: 10vw;
    height: 10vw;
    max-width: 4.5rem;
    max-height: 4.5rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    cursor: pointer;

    &::before {
      @include fluid-type(400px, 700px, 12px, 20px);
      color: white;
      font-family: 'md-icon';
      content: '\e840';
    }

    &:hover {
      background-color: #000;
    }
  }

  &video-play-button-driving-experience {
    position: relative;
    width: 10vw;
    height: 10vw;
    max-width: 4.5rem;
    max-height: 4.5rem;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    cursor: pointer;

    &::before {
      @include fluid-type(400px, 700px, 12px, 20px);
      color: white;
      font-family: 'md-icon';
      content: '\e840';
    }

    &:hover {
      background-color: #000;
    }
  }

  &video-pause-button {
    @extend .mini-video-play-button;

    &::before {
      content: '\e83c';
    }
  }

  &video-download-button {
    position: relative;
    width: 10vw;
    height: 10vw;
    max-width: 50px;
    max-height: 50px;
    background-color: rgba(0, 0, 0, 0.5);
    margin-left: 15px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url('/assets/themes/bmw/icons/bmw_download.svg');
      background-size: 65%;
      background-position: center;
      background-repeat: no-repeat;
    }

    &:hover {
      background-color: #000;
    }
  }

  &env-menu {
    .active {
      color: black;
    }
  }

  &carousel-arrow-prev {
    width: 10vw;
    height: 10vw;
    max-width: 60px;
    max-height: 60px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    cursor: pointer;

    &::before {
      @include fluid-type(400px, 700px, 12px, 20px);
      color: white;
      font-family: 'md-icon';
      content: '\e926';
    }

    &:hover {
      background-color: #000;
    }
  }

  &carousel-arrow-next {
    @extend .mini-carousel-arrow-prev;

    &::before {
      content: '\e929';
    }
  }

  &carousel-dot {
    background: #ccc;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 0 0.5rem;

    &.is-current {
      background: black;
      width: 10px;
      height: 10px;
    }
  }

  &headline {
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'MINISerif';
  }

  &subtitle {
    font-family: 'MINISerif';
    text-transform: uppercase;
    font-weight: 100;
  }

  &logo {
    width: 10%;
    max-width: 4.5rem;
  }

  &footer {
    background: #f1f1f1;
    color: black;
    font-weight: 400;
    font-family: 'MINISansSerif';
    text-transform: uppercase;
    font-size: 12px;
    .copyright {
      color: black;
    }
    .disclaimer {
      text-transform: none;
    }
  }

  &separator-container {
    h1 {
      color: black;
      font-family: 'MINISerif';
    }

    .separator-line {
      background: black;
    }
  }

  &video-menu {
    span {
      font-family: 'MINISansSerif';
    }
  }

  &model-header {
    h1 {
      font-weight: bold;
    }
  }

  &scroll-indicator .border {
    background: black;
  }

  &carousel__nav {
    .circle-go {
      stroke: black;
    }
  }

  &download-in-progress {
    background-color: rgba(0, 0, 0, 0.5);
    width: 10vw;
    height: 10vw;
    max-width: 4.5rem;
    max-height: 4.5rem;
    border-radius: 50%;

    &:hover {
      background-color: black;
    }
  }

  &timeline {
    progress[value] {
      background: rgba(0, 0, 0, 0.5);

      &::-webkit-progress-value {
        background-color: rgba(0, 0, 0, 0.5);
      }

      &::-moz-progress-bar {
        background: rgba(0, 0, 0, 0.5);
      }
    }
  }

  &volume {
    mat-slider {
      --mdc-theme-primary: black;
      --mdc-slider-active-track-color: rgb(80, 80, 80) !important;
      --mdc-slider-inactive-track-color: white !important;

      mat-slider-visual-thumb {
        --mdc-slider-handle-color: rgb(40, 40, 40);
        --mdc-slider-focus-handle-color: rgb(40, 40, 40);
        --mdc-theme-primary: rgb(40, 40, 40);
        --mat-mdc-slider-hover-ripple-color: rgba(0, 0, 0, 0.05);
        --mat-mdc-slider-focus-ripple-color: rgba(75, 75, 75, 0.2);
      }

      .mdc-slider__track--inactive {
        opacity: 0.4 !important;
      }
    }

    progress[value] {
      &::-webkit-progress-value {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}
